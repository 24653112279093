.filter {
display: block;
padding: 10px;
margin: 0 0 10px;
color: #333;
background-color: #f5f5f5;
border: 1px solid #ccc;
border-radius: 4px;
}

.label-default {
    background-color: #ccc;
	color: #222;
}

/* Object list */
.object-block {
	margin: 15px 0 0 0;
	background-color: #f5f5f5;
	border: 1px solid #ccc;
	border-radius: 4px;
	padding: 10px;
}

.object-block img {
	max-height: 150px;
	max-width: 150px;
}

.object-block p {
	font-size: 0.9em;
	line-height: 22px;
	margin: 5px;
	padding: 0;
}

.object-block p .name {
	font-size: 1.1em;
}

.panel-body {
	padding: 0 15px;
}