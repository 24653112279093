.select2-container.form-control {
    background: transparent;
    border: none;
    display: block;
    margin: 0;
    padding: 0;
}

.select2-container .select2-choices .select2-search-field input,
.select2-container .select2-choice,
.select2-container .select2-choices {
    background: none;
    border-radius: 4px;
    color: #555;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    background-color: #FFF;
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    border-color: #ccc;
    padding: 0;
}

.select2-search input {
    border-radius: 4px;
    color: #555;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    background-color: #FFF;
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    border-color: #ccc;
}

.select2-container .select2-choices .select2-search-field input {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.select2-container .select2-choice {
    height: 34px;
    line-height: 1.42857;
}

.select2-container.select2-container-multi.form-control {
    height: auto;
}

.select2-container.input-sm .select2-choice {
    height: 30px;
    line-height: 1.5;
    border-radius: 3px;
}

.input-group-sm .select2-container .select2-choice {
    height: 30px;
    line-height: 1.5;
    border-radius: 3px;
}

.select2-container.input-lg .select2-choice,
.input-group-lg .select2-container .select2-choice {
    height: 45px;
    line-height: 1.33;
    border-radius: 6px;
}

.select2-container-multi .select2-choices .select2-search-field input {
    height: 32px;
    margin: 0;
}

.select2-container-multi.input-sm .select2-choices .select2-search-field input {
    height: 28px;
}

.input-group-sm .select2-container-multi .select2-choices .select2-search-field input {
    height: 28px;
}

.select2-container-multi.input-lg .select2-choices .select2-search-field input,
.input-group-lg .select2-container-multi .select2-choices .select2-search-field input {
    height: 43px;
}

.select2-chosen,
.select2-choice > span:first-child,
.select2-container .select2-choices .select2-search-field input {
    padding: 6px 12px;
}

.input-sm .select2-chosen,
.input-group-sm .select2-chosen,
.input-sm .select2-choice > span:first-child,
.input-group-sm .select2-choice > span:first-child,
.input-sm .select2-choices .select2-search-field input,
.input-group-sm .select2-choices .select2-search-field input {
    padding: 5px 10px;
}

.input-lg .select2-chosen,
.input-group-lg .select2-chosen,
.input-lg .select2-choice > span:first-child,
.input-group-lg .select2-choice > span:first-child,
.input-lg .select2-choices .select2-search-field input,
.input-group-lg .select2-choices .select2-search-field input {
    padding: 10px 16px;
}

.select2-container-multi .select2-choices .select2-search-choice {
    margin-top: 5px;
    margin-bottom: 3px;
    color: #fff;
    background: #0488E0;
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 0 none;
    font-size: 75%;
    font-weight: bold;
}

.select2-container-multi.input-sm .select2-choices .select2-search-choice {
    margin-top: 3px;
    margin-bottom: 2px;
}

.input-group-sm .select2-container-multi .select2-choices .select2-search-choice {
    margin-top: 3px;
    margin-bottom: 2px;
}

.select2-container-multi.input-lg .select2-choices .select2-search-choice,
.input-group-lg .select2-container-multi .select2-choices .select2-search-choice {
    line-height: 24px;
}

.select2-container .select2-choice .select2-arrow, .select2-container .select2-choice div {
    border-left: 1px solid #ccc;
    background: none;
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
}

.select2-dropdown-open .select2-choice .select2-arrow, .select2-dropdown-open .select2-choice div {
    border-left-color: transparent;
    background: none;
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
}

.select2-container .select2-choice .select2-arrow b, .select2-container .select2-choice div b {
    background-position: 0 3px;
}

.select2-dropdown-open .select2-choice .select2-arrow b, .select2-dropdown-open .select2-choice div b {
    background-position: -18px 3px;
}

.select2-container.input-sm .select2-choice .select2-arrow b,
.input-group-sm .select2-container .select2-choice .select2-arrow b,
.select2-container.input-sm .select2-choice div b,
.input-group-sm .select2-container .select2-choice div b {
    background-position: 0 1px;
}

.select2-dropdown-open.input-sm .select2-choice .select2-arrow b,
.input-group-sm .select2-dropdown-open .select2-choice .select2-arrow b,
.select2-dropdown-open.input-sm .select2-choice div b,
.input-group-sm .select2-dropdown-open .select2-choice div b {
    background-position: -18px 1px;
}

.select2-container.input-lg .select2-choice .select2-arrow b,
.input-group-lg .select2-container .select2-choice .select2-arrow b,
.select2-container.input-lg .select2-choice div b,
.input-group-lg .select2-container .select2-choice div b {
    background-position: 0 9px;
}

.select2-dropdown-open.input-lg .select2-choice .select2-arrow b,
.input-group-lg .select2-dropdown-open .select2-choice .select2-arrow b,
.select2-dropdown-open.input-lg .select2-choice div b,
.input-group-lg .select2-dropdown-open .select2-choice div b {
    background-position: -18px 9px;
}

.has-warning .select2-choice,
.has-warning .select2-choices {
    border-color: #c09853;
}

.has-warning .select2-container-active .select2-choice,
.has-warning .select2-container-multi.select2-container-active .select2-choices {
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #dbc59e;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #dbc59e;
    border-color: #a47e3c;
}

.has-warning.select2-drop-active {
    border-color: #a47e3c;
}

.has-warning.select2-drop-active.select2-drop.select2-drop-above {
    border-top-color: #a47e3c;
}

.has-error .select2-choice,
.has-error .select2-choices {
    border-color: #b94a48;
}

.has-error .select2-container-active .select2-choice,
.has-error .select2-container-multi.select2-container-active .select2-choices {
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #d59392;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #d59392;
    border-color: #953b39;
}

.has-error.select2-drop-active {
    border-color: #953b39;
}

.has-error.select2-drop-active.select2-drop.select2-drop-above {
    border-top-color: #953b39;
}

.has-success .select2-choice,
.has-success .select2-choices {
    border-color: #468847;
}

.has-success .select2-container-active .select2-choice,
.has-success .select2-container-multi.select2-container-active .select2-choices {
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #7aba7b;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #7aba7b;
    border-color: #356635;
}

.has-success.select2-drop-active {
    border-color: #356635;
}

.has-success.select2-drop-active.select2-drop.select2-drop-above {
    border-top-color: #356635;
}

.select2-container-active .select2-choice,
.select2-container-multi.select2-container-active .select2-choices {
    outline: none;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(82, 168, 236, 0.6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(82, 168, 236, 0.6);
    -webkit-transition: border-color ease-in-out .15s box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s box-shadow ease-in-out .15s;
    border-color: #66afe9;
}

.select2-drop-active {
    border-color: #66afe9;
}

.select2-drop-auto-width,
.select2-drop.select2-drop-above.select2-drop-active {
    border-top-color: #66afe9;
}

.input-group.select2-bootstrap-prepend [class^=select2-choice] {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
}

.input-group.select2-bootstrap-append [class^=select2-choice] {
    border-bottom-right-radius: 0 !important;
    border-top-right-radius: 0 !important;
}

.select2-dropdown-open [class^=select2-choice] {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

.select2-dropdown-open.select2-drop-above [class^=select2-choice] {
    border-top-right-radius: 0 !important;
    border-top-left-radius: 0 !important;
}

.select2-dropdown-open.select2-drop-above .select2-choice,
.select2-dropdown-open.select2-drop-above .select2-choices {
    background: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.select2-results .select2-highlighted {
    color: #FFF;
    background-color: #428bca;
}

.select2-bootstrap-append .select2-container-multiple,
.select2-bootstrap-prepend .select2-container-multiple {
    vertical-align: top;
}

.select2-bootstrap-append .input-group-btn,
.select2-bootstrap-prepend .input-group-btn {
    vertical-align: top;
}

.select2-bootstrap-append .input-group-btn .btn,
.select2-bootstrap-prepend .input-group-btn .btn {
    vertical-align: top;
}

.select2-container-multi .select2-choices .select2-search-choice-focus {
    background: #ebebeb;
    color: #333;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-color: #adadad;
}

.select2-search-choice-close {
    background: none;
    margin-top: -8px;
    top: 50%;
}

.select2-search-choice-close:after {
    content: 'x';
    font-size: 10px;
    color: #fff;
    padding: 0 4px;
    font-weight: bold;
}

.select2-container .select2-choice abbr {
    top: 50%;
}

.select2-results .select2-no-results,
.select2-results .select2-searching,
.select2-results .select2-selection-limit {
    background-color: #fcf8e3;
    color: #c09853;
}

.select2-container.select2-container-disabled .select2-choice,
.select2-container.select2-container-disabled .select2-choices {
    cursor: not-allowed;
    background-color: #eee;
    border-color: #ccc;
}

.select2-container.select2-container-disabled .select2-choice .select2-arrow, .select2-container.select2-container-disabled .select2-choice div {
    background-color: transparent;
    border-left: 1px solid transparent;
}

.select2-container.select2-container-disabled .select2-choices .select2-arrow, .select2-container.select2-container-disabled .select2-choices div {
    background-color: transparent;
    border-left: 1px solid transparent;
}

.select2-search input.select2-active,
.select2-container-multi .select2-choices .select2-search-field input.select2-active,
.select2-more-results.select2-active {
    background-position: right 4px center;
}

html.dark .select2-container .select2-choices .select2-search-field input,
html.dark .select2-container .select2-choice,
html.dark .select2-container .select2-choices {
    color: #EEE;
    background-color: #282d36;
    border-color: #282d36;
}

html.dark .select2-search {
    position: relative;
}

html.dark .select2-search input {
    color: #EEE;
    background: #2e353e;
    border-color: #242830;
}

html.dark .select2-search input.select2-active {
    background: #2e353e;
}

html.dark .select2-search:before {
    font-family: 'FontAwesome';
    content: "\f002";
    position: absolute;
    right: 15px;
    top: 50%;
    color: #EEE;
    font-size: 12px;
    margin: -11px 0 0;
}

html.dark .select2-drop {
    background-color: #282d36;
    border-color: #21262d;
}

html.dark .select2-container-multi .select2-choices .select2-search-choice {
    color: #EEE;
}

html.dark .select2-container .select2-choice .select2-arrow,
html.dark .select2-container .select2-choice div {
    border-color: #282d36;
}

html.dark .select2-results .select2-result-label {
    color: #EEE;
}

/* Form - Custom Checkbox */
.checkbox-custom {
    position: relative;
    padding: 0 0 0 25px;
    margin-bottom: 7px;
    margin-top: 0;
}

.checkbox-custom.checkbox-inline {
    display: inline-block;
    vertical-align: middle;
}

.form-group .checkbox-custom.checkbox-inline {
    margin-top: 7px;
    padding-top: 0;
}

.checkbox-custom:last-child, .checkbox-custom:last-of-type {
    margin-bottom: 0;
}

.checkbox-custom input[type="checkbox"] {
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 3px;
    margin: -6px 0 0 0;
    z-index: 2;
    cursor: pointer;
}

.checkbox-custom input[type="checkbox"]:checked + label:after {
    position: absolute;
    display: inline-block;
    font-family: 'FontAwesome';
    content: '\F00C';
    top: 50%;
    left: 4px;
    margin-top: -5px;
    font-size: 11px;
    line-height: 1;
    width: 16px;
    height: 16px;
    color: #333;
}

.checkbox-custom input[type="checkbox"]:disabled {
    cursor: not-allowed;
}

.checkbox-custom input[type="checkbox"]:disabled:checked + label:after {
    color: #999;
}

.checkbox-custom input[type="checkbox"]:disabled + label {
    cursor: not-allowed;
}

.checkbox-custom input[type="checkbox"]:disabled + label:before {
    background-color: #eee;
}

.checkbox-custom label {
    cursor: pointer;
    margin-bottom: 0;
    text-align: left;
    line-height: 1.2;
}

.checkbox-custom label:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -9px;
    width: 19px;
    height: 18px;
    display: inline-block;
    border-radius: 2px;
    border: 1px solid #bbb;
    background: #fff;
}

.checkbox-custom label + label.error {
    display: block;
}

html.dark .checkbox-custom label:before {
    background: #282d36;
    border-color: #21262d;
}

html.dark .checkbox-custom input[type="checkbox"]:checked + label:after {
    color: #fff;
}

html.dark .checkbox-custom input[type="checkbox"]:disabled + label:before {
    background: #242830;
    border-color: #242830;
}

html.dark .checkbox-primary input[type="checkbox"]:checked + label:after,
.checkbox-primary input[type="checkbox"]:checked + label:after {
    color: #fff;
}

html.dark .checkbox-primary label:before,
.checkbox-primary label:before {
    background: #0088cc;
    border-color: #0077b3;
}

html.dark .checkbox-text-primary input[type="checkbox"]:checked + label:after,
.checkbox-text-primary input[type="checkbox"]:checked + label:after {
    color: #CCC;
}

html.dark .checkbox-success input[type="checkbox"]:checked + label:after,
.checkbox-success input[type="checkbox"]:checked + label:after {
    color: #fff;
}

html.dark .checkbox-success label:before,
.checkbox-success label:before {
    background: #47a447;
    border-color: #3f923f;
}

html.dark .checkbox-text-success input[type="checkbox"]:checked + label:after,
.checkbox-text-success input[type="checkbox"]:checked + label:after {
    color: #47a447;
}

html.dark .checkbox-warning input[type="checkbox"]:checked + label:after,
.checkbox-warning input[type="checkbox"]:checked + label:after {
    color: #fff;
}

html.dark .checkbox-warning label:before,
.checkbox-warning label:before {
    background: #ed9c28;
    border-color: #e89113;
}

html.dark .checkbox-text-warning input[type="checkbox"]:checked + label:after,
.checkbox-text-warning input[type="checkbox"]:checked + label:after {
    color: #ed9c28;
}

html.dark .checkbox-danger input[type="checkbox"]:checked + label:after,
.checkbox-danger input[type="checkbox"]:checked + label:after {
    color: #fff;
}

html.dark .checkbox-danger label:before,
.checkbox-danger label:before {
    background: #d2322d;
    border-color: #bd2d29;
}

html.dark .checkbox-text-danger input[type="checkbox"]:checked + label:after,
.checkbox-text-danger input[type="checkbox"]:checked + label:after {
    color: #d2322d;
}

html.dark .checkbox-info input[type="checkbox"]:checked + label:after,
.checkbox-info input[type="checkbox"]:checked + label:after {
    color: #fff;
}

html.dark .checkbox-info label:before,
.checkbox-info label:before {
    background: #5bc0de;
    border-color: #46b8da;
}

html.dark .checkbox-text-info input[type="checkbox"]:checked + label:after,
.checkbox-text-info input[type="checkbox"]:checked + label:after {
    color: #5bc0de;
}

html.dark .checkbox-dark input[type="checkbox"]:checked + label:after,
.checkbox-dark input[type="checkbox"]:checked + label:after {
    color: #fff;
}

html.dark .checkbox-dark label:before,
.checkbox-dark label:before {
    background: #171717;
    border-color: #0a0a0a;
}

html.dark .checkbox-text-dark input[type="checkbox"]:checked + label:after,
.checkbox-text-dark input[type="checkbox"]:checked + label:after {
    color: #171717;
}

/* Form - Custom Radio */
.radio-custom {
    position: relative;
    padding: 0 0 0 25px;
    margin-bottom: 7px;
    margin-top: 0;
}

.radio-custom.radio-inline {
    display: inline-block;
    vertical-align: middle;
}

.form-group .radio-custom.radio-inline {
    margin-top: 7px;
    padding-top: 0;
}

.radio-custom:last-child, .radio-custom:last-of-type {
    margin-bottom: 0;
}

.radio-custom input[type="radio"] {
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 3px;
    margin: -6px 0 0 0;
    z-index: 2;
    cursor: pointer;
}

.radio-custom input[type="radio"]:checked + label:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 4px;
    margin-top: -5px;
    display: inline-block;
    font-size: 11px;
    line-height: 1;
    width: 10px;
    height: 10px;
    background-color: #444;
    border-radius: 50px;
    -webkit-box-shadow: 0px 0px 1px #444;
    box-shadow: 0px 0px 1px #444;
}

.radio-custom input[type="radio"]:disabled {
    cursor: not-allowed;
}

.radio-custom input[type="radio"]:disabled:checked + label:after {
    color: #999;
}

.radio-custom input[type="radio"]:disabled + label {
    cursor: not-allowed;
}

.radio-custom input[type="radio"]:disabled + label:before {
    background-color: #eee;
}

.radio-custom label {
    cursor: pointer;
    margin-bottom: 0;
    text-align: left;
    line-height: 1.2;
}

.radio-custom label:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -9px;
    width: 18px;
    height: 18px;
    display: inline-block;
    border-radius: 50px;
    border: 1px solid #bbb;
    background: #fff;
}

.radio-custom label + label.error {
    display: block;
}

html.dark .radio-custom label:before {
    background: #282d36;
    border-color: #21262d;
}

html.dark .radio-custom input[type="radio"]:checked + label:after {
    background-color: #fff;
}

html.dark .radio-custom input[type="radio"]:disabled + label:before {
    background: #242830;
    border-color: #242830;
}

html.dark .radio-primary input[type="radio"]:checked + label:after,
.radio-primary input[type="radio"]:checked + label:after {
    background: #CCC;
    -webkit-box-shadow: 0px 0px 1px #CCC;
    box-shadow: 0px 0px 1px #CCC;
}

html.dark .radio-success input[type="radio"]:checked + label:after,
.radio-success input[type="radio"]:checked + label:after {
    background: #47a447;
    -webkit-box-shadow: 0px 0px 1px #47a447;
    box-shadow: 0px 0px 1px #47a447;
}

html.dark .radio-warning input[type="radio"]:checked + label:after,
.radio-warning input[type="radio"]:checked + label:after {
    background: #ed9c28;
    -webkit-box-shadow: 0px 0px 1px #ed9c28;
    box-shadow: 0px 0px 1px #ed9c28;
}

html.dark .radio-danger input[type="radio"]:checked + label:after,
.radio-danger input[type="radio"]:checked + label:after {
    background: #d2322d;
    -webkit-box-shadow: 0px 0px 1px #d2322d;
    box-shadow: 0px 0px 1px #d2322d;
}

html.dark .radio-info input[type="radio"]:checked + label:after,
.radio-info input[type="radio"]:checked + label:after {
    background: #5bc0de;
    -webkit-box-shadow: 0px 0px 1px #5bc0de;
    box-shadow: 0px 0px 1px #5bc0de;
}

html.dark .radio-primary input[type="radio"]:checked + label:after,
.radio-primary input[type="radio"]:checked + label:after {
    background: #0088CC;
    -webkit-box-shadow: 0px 0px 1px #0088CC;
    box-shadow: 0px 0px 1px #0088CC;
}

html.dark .radio-dark input[type="radio"]:checked + label:after,
.radio-dark input[type="radio"]:checked + label:after {
    background: #171717;
    -webkit-box-shadow: 0px 0px 1px #171717;
    box-shadow: 0px 0px 1px #171717;
}

/* Form - Error Container */
div.validation-message ul {
    display: none;
    list-style: none;
    margin: -15px -15px 15px -15px;
    padding: 15px;
    border-bottom: 1px solid #FFCBCB;
    background: #FFEFEF;
}

div.validation-message ul label.error {
    display: block;
    padding-left: 22px;
    position: relative;
}

div.validation-message ul label.error:before {
    font-family: 'FontAwesome';
    content: '\f00d';
    position: absolute;
    top: 0;
    left: 0;
    font-size: 16px;
    color: #D9534F;
    display: inline-block;
}


.datepicker-dropdown{
    z-index: 100 !important;
}