.form-control:focus {
    border-color: #66afe9;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102, 175, 233, 0.6);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102, 175, 233, 0.6);
}

.collapse{
    visibility: visible;
}

.label-padded label{
    padding-top: 6px;
}

.add-field p{
    margin: 0;
    margin-top: -10px;
    padding-left: 15px;
    padding-top: 0;
}

.add-field i{
    display: none;
}

.dropzone{
    padding: 0;
    min-height: 80px;
}
.dz-message{
    padding: 10px;
}
.dropzone.dz-started .dz-message{
    opacity: 1;
}

.remove-field{
    position: absolute;
    right: -15px;
    font-size: 1.4em;
    color: #da0000;
    top: 50%;
    margin-top: -0.55em;
}

.remove-field:hover{
    color: rgba(218, 0, 0, 0.69);
}


/* ====== EDITOR ======= */
.ajax-image-editor .ajax-box {
    min-height:400px;
}

.ajax-image-editor .owl-carousel .owl-item i.fa-check-circle {
    display: block;
    position: absolute;
    color: #47a447;
    font-size: 32px;
    line-height: 40px;
    top: 0;
    right: 0;
}

.ajax-image-editor .owl-carousel img {
    cursor: pointer;
}

.ajax-image-editor .edit-area {
    width: 100%;
    display: block;
    margin: 0 auto;
    position: relative;
}

.ajax-image-editor .edit-area.paint-over {
    cursor: crosshair;
}

.ajax-image-editor .edit-area.edited {
    border: 1px dotted #cccccc;
}

.ajax-image-editor .edit-area .editor-image {
    max-width: 100%;
    display: block;
    margin: 0 auto;
}

.ajax-image-editor .edit-area .editor-text {
    position: absolute;
    cursor: move;
    margin: 0;
    font-family: "Arial", sans-serif;
    z-index: 10;
}

.ajax-image-editor .edit-area .editor-point {
    position: absolute;
    display: block;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: #0088CC;
    margin: 0;
}

.ajax-image-editor .edit-area .editor-polygons {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
}

.ajax-image-editor .edit-area .editor-polygons polygon{
    cursor: pointer;
}



.expiration-fields input {
    display: inline-block;
}

.expiration-fields .expiration-date {
    width: 55%;
}

.expiration-fields .expiration-time {
    width: 35%;
    margin-left: 5%;
}

#details #map {
    width: 100%;
    height: 400px;
}

.minmax-bid-amount {
    font-size: 11px;
}

tr.bid-accepted a {
    color: #98d9f4;
}
tr.bid-accepted .fa-check {
    color: #fff;
}