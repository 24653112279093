/* MARGIN TOP POSITIVE */
.mt5,
.object-block .mt5,
.add-ajax-content-button.mt5{
    margin-top: 5px;
}
.mt10,
.object-block .mt10,
.add-ajax-content-button.mt10{
    margin-top: 10px;
}
.mt15,
.object-block .mt15,
.add-ajax-content-button.mt15{
    margin-top: 15px;
}
.mt20,
.object-block .mt20,
.add-ajax-content-button.mt20{
    margin-top: 20px;
}
.mt25,
.object-block .mt25,
.add-ajax-content-button.mt25{
    margin-top: 25px;
}
.mt30,
.object-block .mt30,
.add-ajax-content-button.mt30{
    margin-top: 30px;
}
.mt50,
.object-block .mt50,
.add-ajax-content-button.mt50{
    margin-top: 50px;
}

/* MARGIN TOP NEGATIVE */
.mt-5,
.object-block .mt-5,
.add-ajax-content-button.mt5{
    margin-top: -5px;
}
.mt-10,
.object-block .mt-10,
.add-ajax-content-button.mt-10{
    margin-top: -10px;
}
.mt-15,
.object-block .mt-15,
.add-ajax-content-button.mt-15{
    margin-top: -15px;
}
.mt-20,
.object-block .mt-20,
.add-ajax-content-button.mt-20{
    margin-top: -20px;
}


/* MARGIN BOTTOM POSITIVE */
.mb5,
.object-block .mb5,
.add-ajax-content-button.mb5{
    margin-bottom: 5px;
}
.mb10,
.object-block .mb10,
.add-ajax-content-button.mb10{
    margin-bottom: 10px;
}
.mb15,
.object-block .mb15,
.add-ajax-content-button.mb15{
    margin-bottom: 15px;
}
.mb20,
.object-block .mb20,
.add-ajax-content-button.mb20{
    margin-bottom: 20px;
}
.mb25,
.object-block .mb25,
.add-ajax-content-button.mb25{
    margin-bottom: 25px;
}
.mb30,
.object-block .mb30,
.add-ajax-content-button.mb30{
    margin-bottom: 30px;
}
.mb50,
.object-block .mb50,
.add-ajax-content-button.mb50{
    margin-bottom: 50px;
}

/* MARGIN BOTTOM NEGATIVE */
.mb-5,
.object-block .mb-5,
.add-ajax-content-button.mb5{
    margin-bottom: -5px;
}
.mb-10,
.object-block .mb-10,
.add-ajax-content-button.mb-10{
    margin-bottom: -10px;
}
.mb-15,
.object-block .mb-15,
.add-ajax-content-button.mb-15{
    margin-bottom: -15px;
}
.mb-20,
.object-block .mb-20,
.add-ajax-content-button.mb-20{
    margin-bottom: -20px;
}

.pt6 {
    padding-top: 6px;
}


.required{
    display: inline-block;
    color: #d2322d;
    font-size: 0.8em;
    font-weight: bold;
    position: relative;
    top: -0.2em;
}

.no-padding{
    padding: 0;
}


/* WIDTH */
.mw120 {
    min-width: 120px;
}